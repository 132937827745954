"use client";

import { useState, useEffect, Fragment } from "react";
import type { JSX } from "react";
import { useSearchParams, usePathname } from "next/navigation";
import Link from "next/link";
import Image from "next/image";
import { Menu, Transition } from "@headlessui/react";
import { GlobeAltIcon, ChevronDownIcon } from "@heroicons/react/16/solid";
import ButtonSignin from "./ButtonSignin";
import logo from "@/public/icon.png";
import config from "@/config";
import { useTranslations } from 'next-intl';
import { languages } from '../navigation'

const removeLanguagePrefix = (path: string) => {
  const segments = path.split('/').filter(segment => segment !== '');
  if (languages.some(lang => lang.locale === segments[0])) {
    return '/' + segments.slice(1).join('/');
  }
  return path;
};

const Header = ({ locale }: { locale?: string }) => {
  const t = useTranslations('Header');
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const cta: JSX.Element = <ButtonSignin text={t('login')} extraStyle="btn-primary" />;

  const links: { href: string; label: string; }[] = [
    { href: "/#feature", label: t('features') },
    // { href: "/#use-cases", label: t('useCases') },
    { href: "/#how-it-works", label: t('HowItWorks') },
    { href: "/#pricing", label: t('Pricing') },
    { href: "/#faq", label: t('faq') },
    { href: "/dashboard", label: t('Dashboard') },
  ];

  useEffect(() => {
    setIsOpen(false);
  }, [searchParams]);

  return (
    <header className="bg-gradient-to-r from-blue-500 to-purple-600 text-white shadow-lg">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex items-center justify-between h-20" aria-label="Global">
          <div className="flex items-center flex-shrink-0">
            <Link className="flex items-center gap-3" href="/" title={`${config.appName} homepage`}>
              <Image
                src={logo}
                alt={`${config.appName} logo`}
                className="w-10 h-10 rounded-full"
                placeholder="blur"
                priority={true}
                width={40}
                height={40}
              />
              <span className="font-extrabold text-xl">{config.appName}</span>
            </Link>
          </div>

          <div className="hidden lg:flex lg:items-center lg:justify-center lg:flex-1 space-x-6">
            {links.map((link) => (
              <Link href={link.href} key={link.href} className="px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-white hover:bg-opacity-20 transition duration-300 ease-in-out">
                {link.label}
              </Link>
            ))}
          </div>

          <div className="hidden lg:flex lg:items-center lg:justify-end lg:space-x-6">
            <a href="https://twitter.com/intent/user?&region=follow&screen_name=AriaFlow" className="text-white hover:text-blue-200 transition duration-300 ease-in-out flex items-center space-x-2">
              <svg className="h-5 w-5" viewBox="0 0 24 24">
                <path fill="currentColor" d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
              <span className="text-sm">@AriaFlow</span>
            </a>

            {cta}

            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-white bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-600 focus:ring-white transition duration-300 ease-in-out">
                <GlobeAltIcon className="w-5 h-5 mr-2" />
                {!locale || locale === 'default' ? 'EN' : locale.toUpperCase()}
                <ChevronDownIcon className="ml-2 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-30 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {languages.map((item) => {
                      let hrefValue = `/${item.locale}${removeLanguagePrefix(pathname)}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
                      return (
                        <Menu.Item key={item.locale}>
                          <Link href={hrefValue} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                            {item.language}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

          <div className="flex lg:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-200 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              onClick={() => setIsOpen(true)}
            >
              <span className="sr-only">打开主菜单</span>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </nav>
      </div>

      {/* 移动端菜单 */}
      <div className={`lg:hidden ${isOpen ? "block" : "hidden"}`}>
        <div className="fixed inset-0 z-50 bg-gray-900 bg-opacity-50 backdrop-blur-sm" onClick={() => setIsOpen(false)}></div>
        <div className="fixed inset-y-0 right-0 z-50 w-full max-w-sm bg-white shadow-xl">
          <div className="flex items-center justify-between p-4 border-b">
            <Link className="flex items-center gap-2" title={`${config.appName} homepage`} href="/" onClick={() => setIsOpen(false)}>
              <Image
                src={logo}
                alt={`${config.appName} logo`}
                className="w-8 h-8 rounded-full"
                placeholder="blur"
                priority={true}
                width={32}
                height={32}
              />
              <span className="font-extrabold text-lg text-gray-900">{config.appName}</span>
            </Link>
            <button type="button" className="text-gray-400 hover:text-gray-500" onClick={() => setIsOpen(false)}>
              <span className="sr-only">关闭菜单</span>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="px-4 py-6">
            <div className="flex flex-col space-y-4">
              {links.map((link) => (
                <Link
                  href={link.href}
                  key={link.href}
                  className="text-base font-medium text-gray-900 hover:text-blue-600"
                  onClick={() => setIsOpen(false)}
                >
                  {link.label}
                </Link>
              ))}
            </div>
            <div className="mt-6 pt-6 border-t border-gray-200">
              <a href="https://twitter.com/intent/user?&region=follow&screen_name=AriaFlow" className="flex items-center space-x-2 text-blue-600 hover:text-blue-800">
                <svg className="h-5 w-5" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
                <span className="text-sm">@AriaFlow</span>
              </a>
            </div>
            <div className="mt-6">
              {cta}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
